import * as React from "react";

const Global = (props: any) => (
  <svg width={18} height={18} {...props}>
    <path
      d="M15.363 2.637a9 9 0 1 0 0 12.725 8.993 8.993 0 0 0 0-12.725Zm-.376 11.442a9.019 9.019 0 0 0-1.344-1 14.552 14.552 0 0 0 .543-3.511h2.645a7.78 7.78 0 0 1-1.844 4.506ZM1.17 9.573h2.645a14.552 14.552 0 0 0 .543 3.511 9.02 9.02 0 0 0-1.344 1A7.78 7.78 0 0 1 1.17 9.573ZM3.014 3.92a9.019 9.019 0 0 0 1.344 1 14.552 14.552 0 0 0-.543 3.511H1.17A7.78 7.78 0 0 1 3.014 3.92Zm5.413 1.113a7.79 7.79 0 0 1-2.673-.677C6.24 3.1 7.136 1.6 8.427 1.231Zm0 1.149v2.244H4.962A13.51 13.51 0 0 1 5.4 5.453a8.938 8.938 0 0 0 3.027.729Zm0 3.39v2.244a8.938 8.938 0 0 0-3.03.729 13.51 13.51 0 0 1-.435-2.973Zm0 3.393v3.8c-1.29-.372-2.187-1.873-2.673-3.126a7.79 7.79 0 0 1 2.673-.673Zm1.147 0a7.79 7.79 0 0 1 2.673.677c-.486 1.252-1.382 2.753-2.673 3.126Zm0-1.149V9.573h3.464a13.51 13.51 0 0 1-.435 2.973 8.938 8.938 0 0 0-3.03-.729Zm0-3.39V6.182a8.938 8.938 0 0 0 3.03-.729 13.51 13.51 0 0 1 .435 2.973Zm0-3.393v-3.8c1.29.372 2.187 1.874 2.673 3.126a7.791 7.791 0 0 1-2.674.674ZM12.149 1.8a7.817 7.817 0 0 1 2.033 1.3 7.881 7.881 0 0 1-.922.7 8.633 8.633 0 0 0-1.111-2Zm-7.408 2a7.884 7.884 0 0 1-.922-.7 7.817 7.817 0 0 1 2.032-1.3 8.636 8.636 0 0 0-1.11 2Zm0 10.4a8.634 8.634 0 0 0 1.11 2 7.817 7.817 0 0 1-2.033-1.3 7.881 7.881 0 0 1 .923-.7Zm8.518 0a7.885 7.885 0 0 1 .922.7 7.817 7.817 0 0 1-2.033 1.3 8.633 8.633 0 0 0 1.111-2Zm.926-5.772a14.552 14.552 0 0 0-.543-3.511 9.021 9.021 0 0 0 1.344-1 7.78 7.78 0 0 1 1.844 4.506Z"
      fill="gray"
    />
  </svg>
);

export default Global;
